/*----- footer area start -----*/
.footer-top {
    background-color: #f7f7f7;
    [class*="col-"] {
        .widget-item {
            @media #{$md-device, $sm-device} {
                margin-bottom: 30px;
            }
        }
        &:last-child {
            .widget-item {
                margin-bottom: 0;
            }
        }
    }
}
.widget {
    &-title {
        margin-top: -6px;
        margin-bottom: 23px;
    }
}

// contact widget
.contact-block {
    li {
        font-size: 14px;
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
        i {
            font-size: 18px;
            padding-right: 5px;
            vertical-align: middle;
        }
        a {
            color: $paragraph;
            &:hover {
                color: $theme-color;
                letter-spacing: 1.1px;
            }
        }
    }
}
// information widget
.info-list {
    display: grid;
    grid-template-columns: auto auto;
    li {
        margin-bottom: 8px;
        a {
            font-size: 14px;
            color: $paragraph;
            text-transform: capitalize;
            &:hover {
                color: $theme-color;
            }
        }
    }
}
//social link
.social-link {
    margin-top: 30px;
    a {
        width: 40px;
        height: 40px;
        font-size: 18px;
        line-height: 40px;
        margin-right: 10px;
        color: $paragraph-light;
        display: inline-block;
        text-align: center;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid #dedede;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            color: $white;
            background-color: $theme-color;
            border-color: $theme-color;
        }
    }
}

//newsletter area start
.newsletter-wrapper {
    @media #{$sm-device} {
        margin-top: 8px;
    }
}
.newsletter-inner {
    position: relative;
    margin-top: 16px;
    // responsive
    @media #{$sm-device} {
        margin-top: 5px;
    }
    .news-field {
        width: 100%;
        height: 40px;
        border: none;
        padding: 10px 0;
        color: $paragraph-light;
        padding-right: 80px;
        background-color: transparent;
        border-bottom: 1px solid #bcbcbc;
    }
    .news-btn {
        top: 0;
        right: 10px;
        line-height: 40px;
        position: absolute;
        font-size: 14px;
        font-weight: 700;
        color: $theme-color;
        &:hover {
            color: $black-soft;
        }
    }
}

// footer payment method
.footer-payment {
    text-align: right;
    // responsive
    @media #{$sm-device} {
        text-align: center;
        margin-top: 30px;
    }
}
// copyright area 
.footer-bottom {
    padding: 20px 0;
}
.copyright-text {
    p {
        a {
            color: $theme-color;
        }
    }
}
/*----- footer area end -----*/