/*----- product details slider start ----*/
.pro-large-img {
    position: relative;
    cursor: pointer;
    img {
        width: 100%;
        transition: none;
    }
}
.pro-nav-thumb {
    cursor: pointer;
}
.pro-nav {
    margin-top: 20px;
}
/*----- product details slider end ----*/

/*----- product details content start ----*/
.option-title {
    line-height: 1;
    font-weight: 400;
    margin-right: 10px;
    text-transform: capitalize;
}
.product-details-des {
    .product-name {
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
        // responsive
        @media #{$xs-device} {
            font-size: 17px;
        }
    }

    // product review
    .pro-review {
        padding-left: 10px;
        span {
            font-size: 14px;
            line-height: 1;
            color: $paragraph;
        }
    }

    // price box
    .price-box {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    // customer review
    .customer-rev {
        padding-top: 10px;
        a {
            color: $black-soft;
            font-size: 13px;
            text-transform: capitalize;
            &:hover {
                color: $theme-color;
            }
        }
    }

    .offer-text {
        font-weight: 400;
    }

    // Quantity Colors
    .quantity-cart-box {
        margin-bottom: 20px;
    }
    .quantity {
        margin-right: 15px;
        & .pro-qty{
            width: 90px;
            height: 40px;
            border: 1px solid #ddd;
            padding: 0 15px;
            border-radius: 40px;
            float: left;
            & .qtybtn {
                width: 15px;
                display: block;
                float: left;
                line-height: 40px;
                cursor: pointer;
                text-align: center;
                font-size: 16px;
                color: $paragraph;
            }
            & input {
                width: 28px;
                float: left;
                border: none;
                height: 40px;
                line-height: 40px;
                padding: 0;
                text-align: center;
                background-color: transparent;
            }
        }
    } 

    .pro-desc {
        margin-bottom: 20px;
    }

    // Availability
    .availability {
        margin-bottom: 15px;
        i {
            color: #81ca33;
        }
        & span {
            color: $paragraph;
            font-size: 13px;
            font-weight: 500;
            line-height: 1;
            padding-left: 3px;
            text-transform: uppercase;
        }
    }
    // usefull links
    .useful-links {
        display: flex;
        margin-bottom: 20px;
        a {
            font-size: 14px;
            font-weight: 500;
            color: $black-soft;
            margin-right: 20px;
            text-transform: capitalize;
            &:hover {
                color: $theme-color;
            }
            i {
                font-size: 20px;
                padding-right: 5px;
                vertical-align: middle;
            }
        }
    }

    // like icon
    .like-icon {
        padding-top: 20px;
        border-top: 1px solid $bdr-color;
        a {
            color: $white;
            text-align: center;
            display: inline-block;
            font-size: 12px;
            line-height: 22px;
            padding: 0 8px;
            margin-right: 6px;
            border-radius: 3px;
            text-transform: capitalize;
            // responsive
            @media #{$xxs-device} {
                margin-right: 0;
            }
            i {
                padding-right: 5px;
            }
            &.facebook {
                background-color: $facebook;
            }
            &.twitter {
                background-color: $twitter;
            }
            &.pinterest {
                background-color: $pinterest;
            }
            &.google {
                background-color: $google-plus;
            }
            &:hover {
                &.facebook {
                    background-color: darken($facebook, 10%); 
                }
                &.twitter {
                    background-color: darken($twitter, 10%); 
                }
                &.pinterest {
                    background-color: darken($pinterest, 10%); 
                }
                &.google {
                    background-color: darken($google-plus, 10%); 
                }
            }
        }
    }
    // share icon
    .share-icon {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        a {
            color: $paragraph;
            text-align: center;
            display: inline-block;
            font-size: 16px;
            padding: 0 8px;
            margin-right: 5px;
            // responsive
            @media #{$xxs-device} {
                padding: 0 5px;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
    // color option
    .color-option {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 18px;
    }
    // size
    .pro-size {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        .nice-select {
            width: 100px;
            height: 40px;
            line-height: 40px;
            border-radius: 40px;
        }
    }
    // group quantity
    .group-product-table {
        margin-top: 30px;
        margin-bottom: 20px;
        display: block;
        &.table-bordered {
            border: none;
        }
        td {
            width: 33.33%;
            padding: 8px;
            vertical-align: middle;
            a {
                color: $paragraph;
                text-transform: capitalize;
                &:hover {
                    color: $theme-color;
                }
            }
            .pro-qty{
                width: 90px;
                height: 35px;
                border: 1px solid #ddd;
                padding: 0 15px;
                float: left;
                & .qtybtn {
                    width: 15px;
                    display: block;
                    float: left;
                    line-height: 31px;
                    cursor: pointer;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 700;
                    color: $paragraph;
                }
                & input {
                    width: 28px;
                    float: left;
                    border: none;
                    height: 33px;
                    line-height: 33px;
                    padding: 0;
                    text-align: center;
                    background-color: transparent;
                }
            }
        }
    }
}
/*------ countdown style end ------*/

.product-details-des {
    // responsive
    @media #{$md-device} {
        margin-top: 76px;
    }
    @media #{$sm-device} {
        margin-top: 58px;
    }
    &.quick-details {
        margin-top: 0;
        // responsive
        @media #{$md-device} {
            margin-top: 0;
        }
        @media #{$sm-device} {
            margin-top: 30px;
        }
        .product-name {
            // responsive
            @media #{$md-device} {
               font-size: 20px;
            }
            @media #{$sm-device} {
                font-size: 18px;
            }
        }
    }
    &.box-layout {
        padding-top: 47px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        // responsive
        @media #{$md-device, $sm-device} {
            margin-top: 0;
        }
        p {
            max-width: 80%;
            // responsive
            @media #{$md-device, $sm-device} {
                max-width: 100%;
                padding: 0 15px;
            }
        }
    }
    &.sidebar-sticky {
        // responsive
        @media #{$md-device, $sm-device} {
            margin-top: 0;
        }
    }
    &.quick-des {
        p {
            padding-top: 0;
        }
    }
}

/*----- product details content end ----*/

/*----- reviews area start -----*/
.product-review-info {
    .nav {
        &.review-tab {
            li {
                a {
                    color: $white;
                    display: block;
                    font-size: 16px;
                    line-height: 1;
                    text-transform: capitalize;
                    padding: 8px 10px;
                    border: 1px solid $theme-color;
                    border-bottom: none;
                    background-color: $theme-color;
                    &:hover,
                    &.active {
                        color: $paragraph;
                        background-color: $white;
                        border-color: #ddd;
                    }
                    // responsive
                    @media #{$xxs-device} {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .tab-content {
        &.reviews-tab {
            border: 1px solid $bdr-color;
            padding: 15px 20px 20px;
            .table {
                margin-bottom: 0;
                tr {
                    text-transform: capitalize;
                }
            }
        }
        .review-description {
            padding-top: 25px;
            padding-bottom: 25px;
            display: flex;
            align-items: center;
            // responsive
            @media #{$md-device} {
                padding: 15px 0;
            }
            @media #{$sm-device} {
                display: block;
            }
            .tab-thumb {
                flex-basis: 300px;
                // responsive
                @media #{$sm-device} {
                    flex-basis: 220px;
                }
            }
            .tab-des {
                padding-left: 40px;
                flex-basis: calc(100% - 300px);
                // responsive
                @media #{$sm-device} {
                    padding-left: 14px;
                    flex-basis: calc(100% - 220px);
                }
                h3 {
                    color: $theme-color;
                    font-size: 20px;
                    font-weight: 22px;
                    font-weight: 700;
                    text-transform: capitalize;
                    padding-bottom: 10px;
                }
                ul {
                    li {
                        font-size: 13px;
                        list-style: inherit;
                    }
                }
            }
        }
    }
}
.review-form {
    h5 {
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 20px;
        text-transform: capitalize;
        // responsive
        @media #{$xs-device} {
            font-size: 16px;
        }
    }
}
.total-reviews {
    display: flex;
    padding-bottom: 50px;
    // responsive
    @media #{$xxs-device} {
        display: block;
    }
    .rev-avatar {
        flex-basis: 60px;
        max-height: 60px;
        // responsive
        @media #{$xxs-device} {
            margin-bottom: 20px;
        }
    }
    .review-box {
        margin-left: 10px;
        border: 1px solid $bdr-color;
        padding: 10px;
        flex-basis: calc(100% - 70px);
        // responsive
        @media #{$xxs-device} {
            margin-left: 0;
        }
        .post-author {
            padding-bottom: 10px;
            p {
                font-size: 12px;
                font-style: italic;
                text-transform: capitalize;
                span {
                    font-size: 13px;
                }
            }
        }
        p {
            font-size: 14px;
            font-style: italic;
        }
    }
}
/*----- reviews area end -----*/
