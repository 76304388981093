/*------- product item start -------*/
.product-item {
    overflow: hidden;
    .color-categories {
        padding: 13px 0 6px;
    }
}
// product thumb
.product {
    &-item,
    &-list-item {
        &:hover {
            .product-thumb {
                .sec-img {
                    opacity: 1;
                    visibility: visible;
                }
                .pri-img {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            .button-group {
                a {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }
            .cart-hover {
                bottom: 15px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &-thumb {
        position: relative;
        img {
            width: 100%;
        }
        .sec-img {
            top: 0;
            left: 0;
            position: absolute;
            opacity: 0;
            visibility: hidden;
        }
    }
    &-caption {
        padding-top: 17px;
        .product-name {
            font-weight: 400;
            line-height: 1.2;
            padding-bottom: 8px;
            a {
                color: $black-soft;
                text-transform: capitalize;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

.manufacturer-name {
    padding-bottom: 5px;
    line-height: 1;
    a {
        font-size: 14px;
        line-height: 1;
        color: $paragraph-light;
        text-transform: capitalize;
        &:hover {
            color: $theme-color;
        }
    }
}

// color categories
.color-categories {
    line-height: 1;
    li {
        display: inline-block;
        padding: 1px;
        border-radius: 50%;
        border: 1px solid $bdr-color;
        transition: 0.4s;
        &:hover {
            border-color: $theme-color;
        }
        a {
            height: 12px;
            width: 12px;
            display: block;
            border-radius: 50%;
        }
    }
}
.c-lightblue {
    background-color: #b0c4de;
}
.c-darktan {
    background-color: #aa9e78;
}
.c-grey {
    background-color: #808080;
}
.c-brown {
    background-color: #964B00;
}

// product action link
.button-group {
    right: 15px;
    top: 15px;
    position: absolute;
    a {
        width: 38px;
        height: 38px;
        display: block;
        font-size: 18px;
        line-height: 42px;
        color: $black-soft;
        text-align: center;
        border-radius: 50%;
        margin-bottom: 10px;
        background-color: $white;
        transform: scale(0.6);
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;
        & span {
            display: block;
        }
        &:hover {
            color: $theme-color;
        }
    }
}

.cart-hover {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
}

// product badge
.product-label {
    background: $theme-color;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    left: 20px;
    line-height: 1;
    min-width: 46px;
    padding: 4px 12px;
    position: absolute;
    text-align: center;
    text-transform: capitalize;
    z-index: 3;
    span {
        display: block;
        margin-top: -1px;
    }
    &.discount {
        background-color: $black-soft;
    }
    &:nth-child(1) {
        top: 20px;
    }
    &:nth-child(2) {
        top: 48px;
    }
}

// product ratings
.ratings {
    color: #f9bd22;
    font-size: 14px;
    span {
        margin-right: 3px;
    }
}

// price box
.price {
    &-box {
        font-size: 15px;
        line-height: 1;
    }
    &-regular {
        color: $theme-color;
        font-weight: 500;
    }
    &-old {
        color: #999;
        padding-left: 5px;
        font-weight: 300;
    }
}
/*------- product item end -------*/

/*-------- product list item start --------*/
.product-list-item {
    display: flex;
    // responsive
    @media #{$sm-device} {
        display: block !important;
    }
    .product-thumb {
        max-width: 30%;
        flex-basis: 30%;
        height: 100%;
        // responsive
        @media #{$sm-device} {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
}

.product-content-list {
    padding-left: 20px;
    max-width: calc(100% - 30%);
    flex-basis: calc(100% - 30%);
    // responsive
    @media #{$sm-device} {
        padding-left: 0;
        max-width: 100%;
        flex-basis: 100%;
        margin-top: 30px;
    }
    .manufacturer-name {
        padding-bottom: 10px;
    }
    .ratings {
        padding-bottom: 10px;
    }
    .product-name {
        padding:10px 0 12px;
        a {
            color: $black-soft;
            font-weight: 400;
            &:hover {
                color: $theme-color;
            }
        }
    }
    p {
        padding-top: 15px;
        margin-top: 22px;
        border-top: 1px solid $bdr-color;
    }
}
/*-------- product list item end --------*/