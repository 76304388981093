/*------ pagination area style start ------*/
.paginatoin-area {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid $bdr-color;
    .pagination-box {
        display: flex;
        justify-content: center;
        li {
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
            display: inline-block;
            a {
                color: $black-soft;
                height: 36px;
                width: 36px;
                font-size: 14px;
                display: inline-block;
                text-align: center;
                line-height: 36px;
                background-color: #f5f5f5;
                border-radius: 50%;
                i {
                    font-size: 30px;
                    line-height: 36px;
                }
                &:hover {
                    color: $white;
                    border-color: $theme-color;
                    background-color: $theme-color;
                }
            }
            &.active {
                a {
                    color: $white;
                    background-color: $theme-color;
                }
            }
        }
    }
}
/*------ pagination area style end ------*/